<template>
    <product-faq />
</template>
  
<script>
  import ProductFaq from '@/views/product/view/faq/Faq.vue'

  export default {
    components: {
      ProductFaq
    },
    data() {
      return {
        product_id: this.$route.params.id
      }
    },
  }
  </script>
  